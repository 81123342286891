import {AuthorizedHttpClient} from "common/net";
import {CursorPageableResponse, mapCursorPageable, mapPageable, Pageable, PageableResponse} from "common/types";
import {FindReportSliceParams, mapReportSliceParamsToBody} from "reports/types";
import {FindScheduledTaskFilterParam} from "tasks/types";
import {
    CreateHouseSystemParam,
    FindAllHouseSystemsParam,
    FindAllListedHouseSystemsParam,
    HouseSystem,
    HouseSystemResponse,
    ListedHouseSystem,
    mapToHouseSystem,
    UpdateHouseSystemParam
} from "../types";

export class HouseSystemRepository {
    constructor(private readonly client: AuthorizedHttpClient) {
    }

    async findAll(param?: FindAllHouseSystemsParam): Promise<HouseSystem[]> {
        const response = await this.client.get<HouseSystemResponse[]>("v1/houses/systems", {
            signal: param?.signal,
        });
        return response.map(mapToHouseSystem);
    }

    /**
     * Запрос на получение списка объектов, несущих минимальную информацию о системах.
     *
     * В отличие от полноценного {@link findAll} выполняется быстрее за счет отсутствия время затратной для формирования
     * на сервере информации.
     *
     * Опциональное поле {@link param.companyId} определяет системы какой компании необходимо вернуть. В случае отсутствия
     * данного поля будут отданы системы компании, которой принадлежит авторизированный пользователь. Если пользователь имеет
     * роль <i>Диспетчер ЕТД</i>, метод возвращает системы всех компаний, к которым авторизированному пользователю выдан доступ.
     *
     * Для дочерней компании ответ включает системы группы компаний.
     */
    async findAllListed(param: FindAllListedHouseSystemsParam): Promise<Pageable<ListedHouseSystem>> {
        const response = await this.client.get<PageableResponse<ListedHouseSystem>>(
            "/v1/tasks/filters/systems", {
                params: {
                    page: param.page,
                    limit: param.limit,
                    search: param.search,
                    company_id: param.companyId || undefined,
                },
                signal: param.signal,
            });
        return mapPageable(response, item => item);
    }

    async findAllByCompanyId(companyId: number, param?: FindAllHouseSystemsParam): Promise<HouseSystem[]> {
        const response = await this.client.get<HouseSystemResponse[]>(`v1/companies/${companyId}/houses/systems`, {
            signal: param?.signal,
        });
        return response.map(mapToHouseSystem);
    }

    async findAllBySlices(params: FindReportSliceParams): Promise<Pageable<HouseSystem>> {
        const response = await this.client.post<PageableResponse<HouseSystemResponse>>("web/v1/reports/systems", {
            body: mapReportSliceParamsToBody(params),
            signal: params.signal,
        });
        return mapPageable(response, mapToHouseSystem);
    }

    async findAllForScheduledTaskFilter(param: FindScheduledTaskFilterParam) {
        const response = await this.client.get<CursorPageableResponse<HouseSystemResponse>>(
            "web/v1/scheduled-tasks/filters", {
                params: {
                    type_filter: "system",
                    cursor: param.cursor || undefined,
                    limit: param.limit,
                    search: param.search.trim() || undefined,
                    company_id: param.companyId || undefined,
                },
            });
        return mapCursorPageable(response, mapToHouseSystem);
    }

    findAllGranted(userId: number): Promise<number[]> {
        return this.client.get<number[]>(`v1/users/${userId}/granted-systems`);
    }

    findAllByHouseId(companyId: number, houseId: number, signal?: AbortSignal): Promise<HouseSystem[]> {
        return this.client.get(`v1/companies/${companyId}/houses/${houseId}/systems`, {signal});
    }

    createAll(systems: CreateHouseSystemParam[]): Promise<void> {
        return this.client.post(`v1/houses/systems`, {
            body: systems.map(system => ({
                name: system.name,
                icon: system.icon,
                color: system.color,
                company_id: system.companyId,
            })),
        });
    }

    updateAll(systems: UpdateHouseSystemParam[]): Promise<void> {
        return this.client.put(`v1/houses/systems`, {
            body: systems.map(system => ({
                id: system.id,
                company_id: system.companyId,
                name: system.name,
                color: system.color,
                icon: system.icon,
            })),
        });
    }

    deleteAll(systems: number[]): Promise<void> {
        return this.client.delete(`v1/houses/systems`, {
            body: systems,
        });
    }
}
