import createSvgIcon from "./utils";

const IconGeo34 = createSvgIcon((
    <path
        fillRule="evenodd" clipRule="evenodd"
        d="M5.6665 15.5833C5.6665 9.3241 10.7406 4.25 16.9998 4.25C23.2591 4.25 28.3332 9.3241 28.3332 15.5833V16.0472C28.3332 18.4351 27.5794 20.7485 26.1991 22.6667H26.2082L26.1845 22.6869C25.8999 23.0805 25.5891 23.4573 25.2528 23.8146C24.3297 24.7954 18.131 29.8822 16.9998 30.4583C16.9998 30.4583 9.24836 24.3474 8.7469 23.8146C8.41413 23.461 8.10616 23.0883 7.82407 22.6993L7.7915 22.6667H7.80054C6.42027 20.7485 5.6665 18.4351 5.6665 16.0472V15.5833ZM16.9997 10.9795C19.5425 10.9795 21.6038 13.0408 21.6038 15.5837C21.6038 18.1265 19.5425 20.1878 16.9997 20.1878C14.4569 20.1878 12.3955 18.1265 12.3955 15.5837C12.3955 13.0408 14.4569 10.9795 16.9997 10.9795Z"
        stroke="transparent"/>
), {width: 34, height: 34});

export default IconGeo34;
