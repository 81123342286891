import classNames from "classnames";
import useGlobalEvent from "common/hooks/useGlobalEvent";
import {MouseEvent, ReactNode, useEffect, useId, useRef, useState} from "react";
import {createPortal} from "react-dom";
import FocusLock from "react-focus-lock";
import {usePopupStack, usePortal} from "../../hooks";
import {ModalContext} from "./context";
import s from "./Modal.module.css";

type Variant = "compact" | "full-size";

type Props = {
    width?: number;
    height?: number;
    variant?: Variant;
    children?: ReactNode,
    onDismiss: () => void,
}

const classByVariant: Record<Variant, string | null> = {
    "full-size": null,
    "compact": s.modalCompact,
};

export const Modal = (props: Props) => {
    const {width, height, variant = "compact", onDismiss, children} = props;
    const scrimRef = useRef<HTMLDivElement>(null);
    const portalRoot = usePortal("modal-root");
    const modalId = useId();
    const popupHost = usePopupStack();

    useEffect(() => {
        popupHost.putOnStack(modalId);
        return () => popupHost.removeFromStack(modalId);
    }, [popupHost, modalId]);

    const [isScrimPress, setScrimPress] = useState(false);
    const handleScrimDown = (e: MouseEvent) => {
        e.stopPropagation();
        if (e.target === scrimRef.current) {
            setScrimPress(true);
        }
    };
    const handleScrimUp = (e: MouseEvent) => {
        e.stopPropagation()
        if (isScrimPress) {
            onDismiss();
            setScrimPress(false);
        }
    };

    useGlobalEvent("keydown", e => {
        if (e.key === "Escape" && popupHost.isTopOnStack(modalId)) {
            e.stopPropagation();
            onDismiss();
        }
    });

    return createPortal(
        <FocusLock>
            <div ref={scrimRef} className={s.scrim} onMouseDown={handleScrimDown} onMouseUp={handleScrimUp}>
                <div
                    className={classNames(s.modal, classByVariant[variant])}
                    style={{
                        width: width ? `calc(min(${width}px, 100vw))` : undefined,
                        height: height ? `calc(min(${height}px, 100vh))` : undefined,
                        maxWidth: width ? undefined : "100vw",
                        maxHeight: height ? undefined : "100vh",
                    }}>
                    <ModalContext.Provider value={{onDismiss}}>
                        {children}
                    </ModalContext.Provider>
                </div>
            </div>
        </FocusLock>,
        portalRoot,
    );
};
