export class DateFormatter {
    static formatDefault(date: Date) {
        const day = date.getDate().toString().padStart(2, "0");
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const year = date.getFullYear();
        const hour = date.getHours().toString().padStart(2, "0");
        const minutes = date.getMinutes().toString().padStart(2, "0");

        return `${day}.${month}.${year} ${hour}:${minutes}`;
    }

    static formatDateDefault(date: Date) {
        const day = date.getDate().toString().padStart(2, "0");
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const year = date.getFullYear();

        return `${day}.${month}.${year}`;
    }

    static formatTimeDefault(date: Date) {
        const hour = date.getHours().toString().padStart(2, "0");
        const minutes = date.getMinutes().toString().padStart(2, "0");
        return `${hour}:${minutes}`;
    }

    static formatUTCDateDefault(date: Date) {
        const day = date.getUTCDate().toString().padStart(2, "0");
        const month = (date.getUTCMonth() + 1).toString().padStart(2, "0");
        const year = date.getUTCFullYear();

        return `${day}.${month}.${year}`;
    }
}

export function calcWeeksBetween(first: Date, second: Date) {
    return Math.round((second.getTime() - first.getTime()) / (7 * 24 * 60 * 60 * 1000));
}

export function calcMonthsBetween(first: Date, second: Date) {
    return second.getMonth() - first.getMonth() + (12 * (second.getFullYear() - first.getFullYear()))
}

export function toLocalISODate(date: Date): string {
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");
    return `${date.getFullYear()}-${month}-${day}T${hours}:${minutes}:${seconds}`;
}

export function minutesToMs(value: number) {
    return value * 60000;
}

export function adjustTimezoneMillis(date: Date) {
    return date.getTime() - date.getTimezoneOffset() * 60000;
}