import {AuthorizedHttpClient} from "common/net";
import {CreateTaskParam, FullTaskResponse, mapToTask, Task, UpdateTaskParams} from "../types";

export class TaskRepository {
    constructor(
        private readonly client: AuthorizedHttpClient,
    ) {
    }

    async findById(abortSignal: AbortSignal, id: number): Promise<Task> {
        const response = await this.client.get<FullTaskResponse>(`v1/tasks/${id}`, {
            signal: abortSignal,
        });
        return mapToTask(response);
    }

    async findAllByHouseId(signal: AbortSignal, companyId: number, houseId: number, page: number, limit: number): Promise<Task[]> {
        const response = await this.client.get<FullTaskResponse[]>(
            `web/v1/geo/houses/${houseId}/tasks`, {
                signal: signal,
                params: {
                    page: page + 1,
                    company_id: companyId,
                    limit,
                }
            }
        );
        return response.map(mapToTask);
    }

    async create(params: CreateTaskParam): Promise<{ id: number, number: number }> {
        const response = await this.client.post<any>("v1/tasks/", {
            body: params,
        });
        return {
            id: response.id,
            number: response.task_num,
        };
    }

    updateById(id: number, params: UpdateTaskParams, signal?: AbortSignal): Promise<void> {
        let planningDate;
        if (params.planningDate === undefined) {
            planningDate = undefined;
        } else if (params.planningDate === null) {
            planningDate = 0;
        } else {
            planningDate = params.planningDate.getTime() / 1000;
        }

        return this.client.put(`v2/tasks/${id}`, {
            signal,
            body: {
                company_id: params.companyId,
                executor_id: params.executorId,
                system_id: params.systemId,
                type_id: params.typeId,
                payed_date: params.payedDate ? params.payedDate.getTime() / 1000 : undefined,
                ordered_date: params.orderedDate ? params.orderedDate.getTime() / 1000 : undefined,
                received_date: params.receivedDate ? params.receivedDate.getTime() / 1000 : undefined,
                planning_date: planningDate,
                contact_info: params.contactInfo,
                contact_phone: params.contactPhone,
                apartment: params.apartment,
                final_description: params.finalDescription,
            },
        });
    }
}
