export type MapMarkerType = "user" | "house" | "task";

export interface UserMapMarker {
    type: "user";
    lat: number;
    lon: number;
    labels: { id: number, name: string, color: string }[];
    timestamp: Date;
    id: number;
    fullName: string;
    avatarUrl: string | null;
    companyName: string;
    position: string;
    isOnline: boolean;
    isGeoEnabled: boolean;
    badges: { id: number; name: string }[];
}

export interface HouseMapMarker {
    type: "house";
    id: number;
    lat: number;
    lon: number;
    address: string;
    labels: { id: number, name: string, color: string }[];
    companies: { id: number, name: string }[];
}

export interface TaskMapMarker {
    type: "task";
    lat: number;
    lon: number;
    id: number;
    count: number;
    labels: { id: number, name: string, color: string }[];
}

export type MapMarker = UserMapMarker | HouseMapMarker | TaskMapMarker;
export type HouseMapMarkerLabel = "tag" | "system" | "default";
export type TaskMapMarkerLabel = "status" | "type" | "system" | "default";

interface UserMapMarkerResponse {
    type: "user";
    lat: number;
    lon: number;
    employee_id: number;
    timestamp: number;
    first_name: string;
    last_name: string;
    patronymic: string;
    avatar_url: string | null;
    company_name: string;
    position: string;
    is_online: boolean;
    is_geo_enabled: boolean;
    badges: { id: number; name: string }[];
}

interface HouseMapMarkerResponse {
    type: "house";
    house_id: number;
    lat: number;
    lon: number;
    address: string;
    companies: { id: number, name: string }[];
    labels: { id: number, name: string, color: string }[];
}

interface TaskMapMarkerResponse {
    type: "task";
    lat: number;
    lon: number;
    house_id: number;
    count: number;
    labels: { id: number, name: string, color: string }[];
}

export type MapMarkerResponse = UserMapMarkerResponse | HouseMapMarkerResponse | TaskMapMarkerResponse;

export interface FindAllMapMarker {
    types: MapMarkerType[];
    houseIds: number[];
    userIds: number[];
    houseLabel: HouseMapMarkerLabel;
    taskLabel: TaskMapMarkerLabel;
    companyIds: number[];
    signal: AbortSignal;
}

export function mapToMarker(model: MapMarkerResponse): MapMarker | null {
    if (!model.lat || !model.lon) {
        return null;
    }

    if (model.type === "user") {
        return {
            type: "user",
            lat: model.lat,
            lon: model.lon,
            id: model.employee_id,
            labels: [],
            fullName: `${model.last_name} ${model.first_name} ${model.patronymic}`.trim(),
            avatarUrl: model.avatar_url,
            companyName: model.company_name,
            position: model.position,
            isOnline: model.is_online,
            isGeoEnabled: model.is_geo_enabled || false,
            badges: model.badges,
            timestamp: new Date(model.timestamp * 1000),
        };
    }

    if (model.type === "house") {
        return {
            type: "house",
            id: model.house_id,
            lat: model.lat,
            lon: model.lon,
            companies: model.companies,
            address: model.address,
            labels: model.labels,
        };
    }

    if (model.type === "task") {
        return {
            type: "task",
            lat: model.lat,
            lon: model.lon,
            id: model.house_id,
            count: model.count,
            labels: model.labels,
        };
    }

    return null;
}