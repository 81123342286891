import {AuthorizedHttpClient} from "common/net";
import {CreateCalendarTimelineParam, UpdateCalendarTimelineParam} from "../types";

export class CalendarTimelineRepository {
    constructor(private readonly client: AuthorizedHttpClient) {
    }

    create(param: CreateCalendarTimelineParam): Promise<void> {
        const timezoneOffsetSeconds = param.fromDate.getTimezoneOffset() * 60;
        return this.client.post(
            `web/v1/calendar/timelines`, {
                body: {
                    type: param.type,
                    from_date: Math.floor(param.fromDate.getTime() / 1000) - timezoneOffsetSeconds,
                    to_date: Math.floor(param.toDate.getTime() / 1000) - timezoneOffsetSeconds,
                    from_time: param.fromTime,
                    to_time: param.toTime,
                    company_id: param.companyId,
                    users: param.users,
                },
            }
        );
    }

    put(ids: number[], param: UpdateCalendarTimelineParam): Promise<void> {
        const timezoneOffsetSeconds = param.fromDate.getTimezoneOffset() * 60;
        return this.client.put(
            `web/v1/calendar/timelines`, {
                body: {
                    timelines_ids: ids,
                    type: param.type,
                    from_date: Math.floor(param.fromDate.getTime() / 1000) - timezoneOffsetSeconds,
                    to_date: Math.floor(param.toDate.getTime() / 1000) - timezoneOffsetSeconds,
                    from_time: param.fromTime,
                    to_time: param.toTime,
                    company_id: param.companyId,
                },
            }
        );
    }

    delete(id: number): Promise<void> {
        return this.client.delete(`web/v1/calendar/timelines/${id}`);
    }
}
