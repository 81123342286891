import ru from "date-fns/locale/ru";
import DatePicker from "react-datepicker";
import {InputBase} from "../base";
import IconCalendar16 from "../../icons/IconCalendar16";
import {useRef} from "react";

type Props = {
    value: Date | null;
    placeholder?: string;
    min?: Date;
    max?: Date;
    showTimeInput?: boolean;
    isDisabled?: boolean;
    onChange: (value: Date) => void;
};

interface DatePickerRef {
    cancelFocusInput(): void;

    handleBlur(): void;
}

export const DateInput = (props: Props) => {
    const {
        value,
        min,
        max,
        showTimeInput,
        placeholder,
        isDisabled,
        onChange,
    } = props;
    const ref = useRef<DatePickerRef | null>(null);
    return (
        <DatePicker
            ref={ref as any}
            selected={value}
            placeholderText={placeholder}
            dateFormat={showTimeInput ? "dd.MM.yyyy HH:mm" : "dd.MM.yyyy"}
            locale={ru as any}
            minDate={min}
            maxDate={max}
            showTimeInput={showTimeInput}
            enableTabLoop={false}
            shouldCloseOnSelect={true}
            preventOpenOnFocus
            customInput={(
                <InputBase trailing={(
                    <IconCalendar16
                        fill={isDisabled ? "var(--dark-gray-mian)" : undefined}
                        style={{paddingRight: 10}}/>
                )}/>
            )}
            disabled={isDisabled}
            onChange={date => {
                if (date) {
                    onChange(date);
                }
            }}/>
    );
};
