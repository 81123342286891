import {TriState} from "common/components/checkbox";
import {Pageable} from "common/types";
import {formatName} from "common/utils";
import {CompanyRepository} from "companies/repositories";
import {HouseSystemRepository, NewHouseRepository} from "houses/repositories";
import {makeAutoObservable} from "mobx";
import {TaskStatusRepository, TaskTypeRepository} from "tasks/repositories";
import {TaskFilterValue} from "tasks/types";
import {NewUserRepository} from "users/repositories";
import {ListedUser} from "users/types";
import {BaseTaskFilterStore} from "./BaseTaskFilterStore";
import {SerializedFilterBuilderStore} from "./SerializedFilterBuilderStore";

export interface ValueFilterItem {
    title: string;
    value: string;
}

export interface SearchedValueFilter<T> {
    original: T;
    mapped: ValueFilterItem;
}

interface FilterOptions<T> {
    key: string;
    excludeKey?: string;
    existenceKey?: string;
    useExclusionOnly?: boolean;
    title: string;
    shouldAddTitle?: boolean;
    mapValue: (value: T) => { title: string, value: string };
    onSearch: (value: string, page: number) => Promise<T[] | Pageable<T>>;
}

export class ValueFilterStore<T> implements BaseTaskFilterStore {
    state: TriState;
    selected: ValueFilterItem[];
    readonly isVisible = true;

    constructor(private readonly options: FilterOptions<T>) {
        this.state = TriState.Unchecked;
        this.selected = [];
        makeAutoObservable(this, {}, {autoBind: true});
    }

    get isActive() {
        if (this.options.existenceKey) {
            return this.state === TriState.Checked || this.state === TriState.Indeterminate;
        }

        return this.state !== TriState.Unchecked && this.selected.length > 0;
    }

    get title() {
        return this.options.title;
    }

    toggle() {
        if (this.state === TriState.Indeterminate) {
            this.state = TriState.Unchecked;
            this.selected = [];
        } else if (this.state === TriState.Checked) {
            this.state = TriState.Indeterminate;
        } else {
            this.state = TriState.Checked;
        }
    }

    setSelected(values: ValueFilterItem[]) {
        this.selected = values;
    }

    async searchItems(search: string, page: number): Promise<{
        canLoadMore: boolean,
        items: SearchedValueFilter<T>[]
    }> {
        const result = await this.options.onSearch(search, page);
        if (Array.isArray(result)) {
            return {
                canLoadMore: false,
                items: result.map(item => ({
                    original: item,
                    mapped: this.options.mapValue(item),
                })),
            };
        } else {
            return {
                canLoadMore: result.maxPage > page + 1,
                items: result.items.map(item => ({
                    original: item,
                    mapped: this.options.mapValue(item),
                })),
            };
        }
    }

    isOwnFilter(key: string) {
        return this.options.key === key || this.options.existenceKey === key || this.options.excludeKey === key;
    }

    apply(builder: SerializedFilterBuilderStore) {
        if (this.selected.length > 0) {
            const values = this.selected.map(item => ({
                title: this.options.shouldAddTitle ? `${this.options.title}: ${item.title}` : item.title,
                value: item.value
            }));

            if (this.options.excludeKey && this.state === TriState.Indeterminate) {
                builder.upsertMany(this.options.excludeKey, values);
            } else {
                builder.upsertMany(this.options.key, values);
            }

            return;
        }

        if (this.options.useExclusionOnly && this.options.excludeKey && this.state === TriState.Indeterminate) {
            builder.upsert(this.options.excludeKey, this.options.title, "");
            return;
        }

        if (this.options.existenceKey) {
            builder.upsert(
                this.options.existenceKey,
                this.options.title,
                this.state === TriState.Checked ? "true" : "false",
            );
        }
    }

    deserialize(builder: SerializedFilterBuilderStore) {
        if (this.options.excludeKey && builder.has(this.options.excludeKey)) {
            this.selected = this.mapDeserialized(builder.findMany(this.options.excludeKey) || []);
            this.state = TriState.Indeterminate;
            return;
        }

        if (this.options.existenceKey && builder.has(this.options.existenceKey)) {
            this.state = builder.findValue(this.options.existenceKey) === "true"
                ? TriState.Checked
                : TriState.Unchecked;
            return;
        }

        const selected = builder.findMany(this.options.key);
        if (selected) {
            this.selected = this.mapDeserialized(selected);
            this.state = TriState.Checked;
            return;
        }

        this.reset();
    }

    reset() {
        this.state = TriState.Unchecked;
        this.selected = [];
    }

    private mapDeserialized(value: TaskFilterValue[]): ValueFilterItem[] {
        return value.map(item => {
            // Фильтры сохраняются в формате "Создатель: <имя>".
            // В InputBox нужно отображать только имя, поэтому убираем приписку, если она есть
            let title = item.title;
            if (item.title.startsWith(this.title)) {
                const split = item.title.split(": ", 2);
                title = split[1];
            }
            return {
                title,
                value: item.value
            };
        });
    }
}

export class ValueFiltersFactory {
    static createAddressFilter(houseRepo: NewHouseRepository, companyId: number | null) {
        return new ValueFilterStore({
            key: "houses",
            excludeKey: "exclude_houses",
            useExclusionOnly: true,
            title: "Адрес",
            onSearch: async (search: string, page: number) => {
                return houseRepo.findAllListed({
                    search,
                    companyId,
                    page,
                    limit: 25,
                });
            },
            mapValue: item => ({
                title: item.address,
                value: item.id.toString(),
            }),
        });
    }

    static createCreatorFilter(userRepo: NewUserRepository, companyId: number | null) {
        return new ValueFilterStore({
            key: "creators",
            excludeKey: "exclude_creators",
            useExclusionOnly: true,
            title: "Создатель",
            onSearch: async (search: string, page: number) => {
                return userRepo.findAllListedCreators({
                    search,
                    page,
                    limit: 25,
                    companyId: companyId,
                });
            },
            mapValue: this.mapUserValue,
            shouldAddTitle: true,
        });
    }

    static createCommentAuthorFilter(userRepo: NewUserRepository, companyId: number) {
        return new ValueFilterStore({
            key: "comment_authors",
            excludeKey: "exclude_comment_authors",
            existenceKey: "comments",
            title: "Комментарий",
            onSearch: async (search: string, page: number) => {
                return userRepo.findAllListedCommentAuthors({
                    search,
                    companyId,
                    page,
                    limit: 25,
                });
            },
            mapValue: this.mapUserValue,
            shouldAddTitle: true,
        });
    }

    static createHistoryAuthorFilter(userRepo: NewUserRepository, companyId: number) {
        return new ValueFilterStore({
            key: "history_authors",
            excludeKey: "exclude_history_authors",
            title: "История",
            onSearch: async (search: string, page: number) => {
                return userRepo.findAllListedHistoryAuthors({
                    search,
                    companyId,
                    page,
                    limit: 25,
                });
            },
            mapValue: this.mapUserValue,
            shouldAddTitle: true,
        });
    }

    static createExecutorFilter(userRepo: NewUserRepository, companyId: number | null) {
        return new ValueFilterStore({
            key: "executors",
            excludeKey: "exclude_executors",
            useExclusionOnly: true,
            title: "Исполнитель",
            onSearch: async (search: string, page: number) => {
                return userRepo.findAllListedExecutors({
                    search,
                    companyId,
                    page,
                    limit: 25,
                });
            },
            mapValue: this.mapUserValue,
            shouldAddTitle: true,
        });
    }

    static createStatusFilter(statusRepo: TaskStatusRepository) {
        return new ValueFilterStore({
            key: "statuses",
            excludeKey: "exclude_statuses",
            useExclusionOnly: true,
            title: "Статус",
            onSearch: async (search: string) => {
                return (await statusRepo.findAll())
                    .filter(item => item.localizedName.toLowerCase().includes(search.toLowerCase()));
            },
            mapValue: item => ({
                title: item.localizedName,
                value: item.id.toString(),
            }),
        });
    }

    static createTypeFilter(typeRepo: TaskTypeRepository) {
        return new ValueFilterStore({
            key: "types",
            excludeKey: "exclude_types",
            useExclusionOnly: true,
            title: "Тип",
            onSearch: async (search: string) => {
                return (await typeRepo.findAll())
                    .filter(item => item.name.toLowerCase().includes(search.toLowerCase()));
            },
            mapValue: item => ({
                title: item.name,
                value: item.id.toString(),
            }),
        });
    }

    static createCompanyFilter(companyRepo: CompanyRepository, companyId: number | null) {
        return new ValueFilterStore({
            key: "companies",
            excludeKey: "exclude_companies",
            useExclusionOnly: true,
            title: "Компания",
            onSearch: async (search: string, page: number) => {
                return companyRepo.findAllListed({
                    search,
                    page,
                    companyId: companyId,
                });
            },
            mapValue: item => ({
                title: item.name,
                value: item.id.toString(),
            }),
        });
    }

    static createSystemFilter(systemRepo: HouseSystemRepository, companyId: number | null) {
        return new ValueFilterStore({
            key: "systems",
            excludeKey: "exclude_systems",
            useExclusionOnly: true,
            title: "Система",
            onSearch: async (search: string, page: number) => {
                return systemRepo.findAllListed({
                    search,
                    page,
                    limit: 25,
                    companyId: companyId,
                });
            },
            mapValue: item => ({
                title: item.name,
                value: item.id.toString(),
            }),
        });
    }

    private static mapUserValue(user: ListedUser): ValueFilterItem {
        return {
            title: formatName(user.fullName),
            value: user.id.toString(),
        };
    }
}