import {P} from "../typography";
import s from "./ContextMenu.module.css";
import classNames from "classnames";
import {RadioButton} from "../radio_button";

type Props = {
    children: string;
    isSelected: boolean;
    onToggle: () => void;
}

export const RadioMenuItem = (props: Props) => {
    const {children, isSelected, onToggle} = props;
    return (
        <P className={classNames({
            [s.menuItem]: true,
            [s.menuItemRadio]: true,
        })} onClick={onToggle}>
            <RadioButton isSelected={isSelected}/>
            {children}
        </P>
    );
};
