import {useMemo} from "react";

export function usePortal(portalName: string) {
    return useMemo<HTMLElement>(() => {
        let portalElem = document.getElementById(portalName);
        if (!portalElem) {
            portalElem = document.createElement("div");
            portalElem.id = portalName;
            document.body.appendChild(portalElem);
        }

        return portalElem;
    }, [portalName]);
}