import {P} from "../typography";
import s from "./RadioButton.module.css";

type Props = {
    label?: string;
    isSelected: boolean;
    onToggle?: (value: boolean) => void;
};

export const RadioButton = (props: Props) => {
    const {label, isSelected, onToggle} = props;

    const handleToggle = () => onToggle?.(!isSelected);
    return (
        <div className={s.container} onClick={handleToggle}>
            <span className={isSelected ? s.indicatorContainerActive : s.indicatorContainer}></span>
            {label && (
                <P variant="Paragraph/Small/p">{label}</P>
            )}
        </div>
    )
}
