export interface HouseEmployee {
    id: number;
    fullName: string;
    avatarUrl: string;
    companyId: number;
    companyName: string;
    dispatcherCompanies: { id: number, name: string }[];
    position: string;
    shouldChangeCompany: boolean;
}

export interface HouseEmployeeResponse {
    id: number;
    name: string;
    avatar: string;
    company_id: number;
    company_name: string;
    dispatcher_companies: { id: number, name: string }[];
    should_company_change: boolean;
    position: string;
}

export function mapToHouseEmployee(response: HouseEmployeeResponse): HouseEmployee {
    return {
        id: response.id,
        fullName: response.name,
        avatarUrl: response.avatar,
        position: response.position,
        companyId: response.company_id,
        companyName: response.company_name,
        dispatcherCompanies: response.dispatcher_companies,
        shouldChangeCompany: response.should_company_change,
    };
}
