import {CalendarEventType} from "./CalendarUser";

export interface UserGeoDetailsResponse {
    date: string;
    badges: {
        id: number;
        name: string;
        type: CalendarEventType;
    }[];
    locations: UserGeoLocationResponse[];
}

export interface UserGeoLocationResponse {
    lat: number;
    lon: number;
    timestamp: number;
}

export interface UserGeoDetails {
    date: Date;
    stringDate: string;
    badges: {
        id: number;
        name: string;
        type: CalendarEventType;
    }[];
    locations: UserGeoLocation[];
}

export interface UserGeoLocation {
    lat: number;
    lon: number;
    createdAt: Date;
}

export interface FindAllUserGeoDetailParam {
    userId: number;
    page: number;
    limit: number;
    fromDate?: number;
    toDate?: number;
    signal: AbortSignal;
}

export function mapToUserGeoDetails(response: UserGeoDetailsResponse): UserGeoDetails {
    return {
        date: new Date(response.date),
        stringDate: response.date,
        badges: response.badges,
        locations: response.locations.map(location => ({
            lat: location.lat,
            lon: location.lon,
            createdAt: new Date(location.timestamp * 1000),
        }))
    };
}