import {AuthorizedHttpClient} from "../../common/net";
import {FindAllMapMarker, MapMarker, MapMarkerResponse, mapToMarker} from "../types";

export class MapMarkerRepository {
    constructor(private readonly client: AuthorizedHttpClient) {
    }

    async findAll(param: FindAllMapMarker) {
        const response = await this.client.get<MapMarkerResponse[]>(
            "web/v1/geo/map", {
                signal: param.signal,
                isUseQueryArrays: true,
                params: {
                    house_labels: param.houseLabel,
                    task_labels: param.taskLabel,
                    company_ids: param.companyIds,
                    types: param.types,
                    house_ids: param.houseIds,
                    user_ids: param.userIds,
                },
            });
        return response.map(mapToMarker).filter(it => it) as MapMarker[];
    }
}
