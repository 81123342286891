export type CalendarEventType = "work" | "duty" | "vacation" | "sick_leave";

export interface GetCalendarUserParam {
    signal: AbortSignal;
    from_date: Date;
    to_date: Date;
    companyId: number | null;
    page: number;
    limit: number;
    search: string;
}

export interface GetCalendarTimelinesParam {
    signal: AbortSignal;
    from_date: Date;
    to_date: Date;
    companyId: number | null;
    mode: "include" | "exclude";
    users: number[];
    search: string;
}

export interface CalendarUser {
    id: number;
    fullName: string;
    companyId: number;
    companyName: string;
    avatarUrl: string | null;
    position: string;
    badges: CalendarUserBadge[];
    timelines: CalendarTimeline[];
}

export interface CalendarTimeline {
    id: number;
    type: CalendarEventType;
    fromDate: Date;
    toDate: Date;
    fromTime: string;
    toTime: string;
}

export interface CalendarTimelineResponse {
    id: number;
    type: CalendarEventType;
    from_date: number;
    to_date: number;
    from_time: string;
    to_time: string;
}

export interface CalendarUserBadge {
    text: string;
    type: CalendarEventType;
}

export interface CalendarUserResponse {
    id: number;
    first_name: string;
    second_name: string;
    middle_name: string;
    company_id: number;
    company_name: string;
    avatar_url: string | null;
    position: string;
    badges: CalendarUserBadge[];
    timelines?: CalendarTimelineResponse[];
}

const badgePriority: Record<CalendarEventType, number> = {
    work: 10,
    duty: 8,
    sick_leave: 4,
    vacation: 1,
}

export function mapToCalendarUser(response: CalendarUserResponse): CalendarUser {
    return {
        id: response.id,
        fullName: `${response.second_name} ${response.first_name} ${response.middle_name}`.trim(),
        avatarUrl: response.avatar_url,
        position: response.position,
        badges: response.badges ? response.badges.sort((a, b) => {
            return badgePriority[b.type] - badgePriority[a.type];
        }) : [],
        companyId: response.company_id,
        companyName: response.company_name,
        timelines: response.timelines ? response.timelines.map(timeline => {
            const toDate = new Date(timeline.to_date * 1000);
            const fromDate = new Date(timeline.from_date * 1000);
            fromDate.setHours(0, 0, 0, 0);
            toDate.setHours(23, 59, 59, 999);
            return {
                id: timeline.id,
                type: timeline.type,
                fromDate: fromDate,
                toDate: toDate,
                fromTime: timeline.from_time || "",
                toTime: timeline.to_time || "",
            };
        }) : [],
    };
}